import img0 from "./images/img (0).JPEG"
import img37 from "./images/img (1).jpg"
import img1 from "./images/img (2).jpg"
import img2 from "./images/img (3).jpg"
import img3 from "./images/img (4).jpg"
import img4 from "./images/img (5).jpg"
import img5 from "./images/img (6).jpg"
import img6 from "./images/img (7).jpg"
import img7 from "./images/img (8).jpg"
import img8 from "./images/img (9).jpg"
import img9 from "./images/img (10).JPEG"
import img10 from "./images/img (11).JPEG"
import img11 from "./images/img (12).JPEG"
import img12 from "./images/img (13).JPEG"
import img13 from "./images/img (14).JPEG"
import img14 from "./images/img (15).JPEG"
import img15 from "./images/img (16).JPEG"
import img16 from "./images/img (17).JPEG"
import img17 from "./images/img (18).JPEG"
import img18 from "./images/img (19).JPEG"
import img19 from "./images/img (20).JPEG"
import img20 from "./images/img (21).JPEG"
import img21 from "./images/img (22).JPEG"
import img22 from "./images/img (23).JPEG"
import img23 from "./images/img (24).JPEG"
import img24 from "./images/img (25).JPEG"
import img25 from "./images/img (26).JPEG"
import img26 from "./images/img (27).JPEG"
import img27 from "./images/img (28).JPEG"
import img28 from "./images/img (29).JPEG"
import img29 from "./images/img (30).JPEG"
import img30 from "./images/img (31).JPEG"
import img31 from "./images/img (32).JPEG"
import img32 from "./images/img (33).JPEG"
import img33 from "./images/img (34).JPEG"
import img34 from "./images/img (35).JPEG"
import img35 from "./images/img (36).JPEG"
import img36 from "./images/img (37).JPEG"

import img101 from "./images/img (2).JPEG"
import img201 from "./images/img (3).JPEG"
import img301 from "./images/img (4).JPEG"
import img401 from "./images/img (5).JPEG"
import img501 from "./images/img (6).JPEG"
import img601 from "./images/img (7).JPEG"
import img701 from "./images/img (8).JPEG"
import img801 from "./images/img (9).JPEG"

import img38 from "./images/img38.JPG"
import img39 from "./images/img39.JPG"
import img40 from "./images/img40.JPG"
import img41 from "./images/img41.JPG"

const Commercial = [
  img37,
  img801,
  img38,
  img39,
  img8,
  img9,
  img101,
  img201,
  img10,
  img40,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img3,
  img4,
  img30,
  img31,
  img32,
  img6,
  img7,
  img33,
  img34,
  img35,
  img36,
  img0,
  img301,
  img401,
  img501,
  img601,
  img701,
  img1,
  img2,
  img5,
  img41,
]

export default Commercial
