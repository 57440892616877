const AnimationData = [
  {
    id: 1,
    srcs: "https://www.youtube.com/embed/akrgkihn7dE?si=DukuAOvdaSfJzU5w",
  },
  {
    id: 2,
    srcs: "https://www.youtube.com/embed/G88enGS7LuE?si=WP-1kQAgBvQqgjwt",
  },
  {
    id: 3,
    srcs: "https://www.youtube.com/embed/v4CjWbvmx6Y?si=0A52h3MDbagN40yL",
  },
  {
    id: 4,
    srcs: "https://www.youtube.com/embed/z1ZjI3-2Gjo?si=KM3a7fDdik6P3a2i",
  },
  {
    id: 5,
    srcs: "https://www.youtube.com/embed/Ykktk18mESA?si=pf5dgtrXV2QEyEk3",
  },
]
export default AnimationData
